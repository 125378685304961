<template>
  <TsCard no-border :data-testid="`${props.dataTestid}-card`" body-class="py-0"
    footer-class="pt-0 flex flex-col mt-auto flex-1" :append-class="twMerge(
      'bg-white flex flex-col h-full w-full drop-shadow-[1px_5px_12px_0px_#00152A0F] text-left relative'
    )" :id="props.id || `product-${product?.code}-card-` + random_id">
    <template #header>
      <TsRow append-class="m-0 justify-between items-center mb-2">
        <div role="status" @click="emit('freeze')" class="!cursor-pointer select-none">
          <TsButton
            :append-class="twMerge('px-6 font-medium py-0.5', isFreezed ? 'bg-primary text-white' : 'bg-white text-primary')"
            size="xs" rounded variant="info" :label="isFreezed ? 'Freezed' : 'Freeze'" outlined />
        </div>
        <TsIcon class="cursor-pointer rounded transition-colors hover:bg-info/10" name="bitcoin-icons:cross-filled"
          size="24" @click="handleClearProduct" />
      </TsRow>

      <TsRow append-class="m-0 py-6" isCentered>
        <NuxtLink :to="`/product/${product?.slug}-${product?.code}`">
          <NuxtImg :src="product?.image" :width="isMobile ? '105' : '120'" height="105" />
        </NuxtLink>
      </TsRow>

      <TsRow append-class="m-0 items-end">
        <TsTypography append-class="ms-auto" size="xs"><strong>ID: </strong>{{ product?.code }}</TsTypography>
      </TsRow>
    </template>

    <template #content>
      <TsTag v-if="limitedTimeOffer.expiresIn" variant="danger" append-class="mb-3" size="xs" rounded>
        <Icon name="oi:timer" size="12" />{{ limitedTimeOffer.text }}
      </TsTag>

      <NuxtLink :to="`/product/${product?.slug}-${product?.code}`">
        <TsTypography append-class="line-clamp-2 text-natural-black mb-2 hover:underline"><strong>{{ product?.brand
            }}</strong> {{ product?.full_name }}</TsTypography>
      </NuxtLink>
      <TsMedia :append-class="twMerge('items-center gap-2', product_layout === 'mobile-grid' && 'flex-col-reverse items-start',
        props.priceAndRatingsContainerClass)">
        <TsMediaContent>
          <TsTypography variant="h2" weight="bold" append-class="text-idle-black leading-normal mb-0 leading-tight">
            {{ rootStore.isIncVat ? product?.prices?.formatted.gross : product?.prices?.formatted.net }}
            <span v-if="product?.prices?.was_price_raw ?? 0 > 0"
              class="line-through leading-tight text-natural-grey font-medium text-sm">
              {{ product?.prices?.was_price_formatted }}
            </span>
            <TsTypography v-if="product?.prices?.formatted.per_unit" as="span" variant="xs"
              append-class="text-natural-silver-grey leading-tight">
              {{ product?.prices?.formatted.per_unit }}
            </TsTypography>
          </TsTypography>
          <TsTypography as="span" variant="xs" append-class="leading-tight">
            {{ rootStore.isIncVat ? 'Excl.' : 'Inc.' }} VAT
            {{ rootStore.isIncVat ? product?.prices?.formatted.net : product?.prices?.formatted.gross }}
          </TsTypography>
        </TsMediaContent>
      </TsMedia>

      <TsRatings v-if="productReviews" :modelValue="productRatings" :label="`(${productReviews})`" />
    </template>

    <template #footer>


      <div data-testid="product-specication" class="px-0 first:border-y-0 py-2 cursor-pointer" @click="handleAccordion">
        <TsRow append-class="m-0 justify-between">
          <TsTypography append-class="text-sm text-idle-black font-semibold">Technical Specifications</TsTypography>
          <TsIcon :name="compareStore.isSpecificationEnable ? 'fe:arrow-up' : 'fe:arrow-down'" size="22" />
        </TsRow>
        <div v-if="compareStore.isSpecificationEnable" class="flex flex-col mb-3">
          <div class="border-b py-2" v-for="Specifications in props?.product?.technical_spec_attributes"
            :key="Specifications.id">
            <TsTypography variant="sm" append-class="mb-1">{{ Specifications.key }}</TsTypography>
            <TsTypography variant="sm" weight="semibold" append-class="text-idle-black">{{ Specifications.value }}
            </TsTypography>
          </div>
        </div>
      </div>

      <div class="mt-auto space-y-3">
        <TsQuantityCounter v-model="productQuantity" data-testid="product-compare-trolley-preview" />
        <TsButton append-class="max-md:px-1" icon="bx:cart" size="sm" block label="Add to trolley"
          data-testid="add-to-trolley-button" :disabled="isLoading" :isLoading="isLoading"
          @click="handleTrolleyInit(props?.product as any)"/>
      </div>

    </template>
  </TsCard>
</template>

<script setup lang="ts">
import { twMerge } from "tailwind-merge";
import { useRandomUUID } from "../../composables/useRandomUUID";
import { data } from "../../json/product.json";
import type { Product } from "~/types/ecom/product/product.type";
import { toast } from "vue3-toastify";

const emit = defineEmits<{
  freeze: any;
  removeItem: any;
}>();

type Props = {
  product?: Partial<Product> | null;
  appendClass?: string;
  limitedTimeOffer?: {
    text: string;
    expiresIn?: string | Date;
  };
  // Product props
  dataTestid?: string;
  id?: string;
  layout?: "grid" | "list";
  tags?: string[];
  priceAndRatingsContainerClass?: string;
  ratingsContainerClass?: string;
  productImageWidth?: string;
  productImageHeight?: string;
  isFreezed?: boolean;
};

const props = withDefaults(defineProps<Props>(), {
  product: () => data,
  limitedTimeOffer: () => ({
    text: "Limited time deal",
  }),
  appendClass: "",
  priceAndRatingsContainerClass: "",
  ratingsContainerClass: "",
  tags: () => ["New", "20% off"],
  isFreezed: false
});

const random_id = ref("");
const { isMobile, isDesktop } = useDevice();
const rootStore = useRootStore();
const trolleyStore = useTrolleyStore();
const branchStore = useBranchStore();
const compareStore = useCompareStore();
const productAttributeStore = useProductAttributeStore();
const productQuantity = ref(1);
const isLoading = ref(false);

onMounted(() => {
  random_id.value = useRandomUUID();
});

function handleAccordion() {
  compareStore.isSpecificationEnable = !compareStore.isSpecificationEnable;
}

async function handleTrolleyInit(product: Product) {
  try {
    isLoading.value = true;
    await trolleyStore.addToTrolleyForDelivery(product.code, productQuantity.value);
    toast("Successfully added product to trolley", {
      autoClose: true,
      type: "success",
    });
  } catch (error) {
    toast("Something went wrong, please try later", {
      autoClose: true,
      type: "error",
    });
  } finally {
    isLoading.value = false;
  }
  productQuantity.value = 1;
}

const product_layout = computed(() => {
  if (isMobile && props.layout === "grid") {
    return `mobile-${props.layout}`;
  } else if (isMobile && props.layout === "list") {
    return `mobile-${props.layout}`;
  } else if (isDesktop && props.layout === "grid") {
    return `desktop-${props.layout}`;
  } else if (isDesktop && props.layout === "list") {
    return `desktop-${props.layout}`;
  }
  return "";
});

const productReviews = ref(
  productAttributeStore.getNumberOfReviews(props.product?.other_attributes!)
);

function convertToNearestWholeNumber(num: number) {
  const firstDecimalPlace = Math.floor(num * 10) % 10;
  if (firstDecimalPlace < 5) {
    return Math.floor(num);
  } else {
    return Math.ceil(num);
  }
}

function handleClearProduct() {
  if (compareStore.checkedProduts.length === 1) compareStore.isCompareRowVisible = false;
  if (compareStore.freezedProductCode && compareStore.freezedProductCode === props.product?.code) {
    compareStore.freezedProductCode = undefined;
  }
  emit('removeItem', props.product);
}

const productRatings = ref(
  convertToNearestWholeNumber(
    Number(
      productAttributeStore.getProductRating(props.product?.other_attributes!)
    )
  )
);

</script>
